import React from 'react';
import { connect } from 'react-redux';

import Default from './default';
import Integrated from './integrated';
import * as organisationSelector from '../../../organisation/selectors/organisation';

require('../../styles.scss');

const Schedules = ({ organisation }) => (
  organisation.integration && organisation.integration.external_schedule
    ? <Integrated />
    : <Default />
);

const mapStateToProps = (state) => ({
  organisation: organisationSelector.selected(state),
});

export default connect(mapStateToProps)(Schedules);
