import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Calendar from 'react-big-calendar';
import moment from 'moment';

import Overview from '@common/components/overview';
import AddExchangeForm from '../../../flexchange/forms/add-exchange/create';
import Container from '@common/components/container';
import ToolBar from '@common/components/tool-bar';
import WeekSelector from '@common/components/week-selector';

import * as AnalyticsService from '@common/services/analytics';
import { useAppSelector } from '@common/hooks';
import * as schedulesSelector from '../../selectors/schedules';

import fetchShifts from '../../actions/fetch-shifts';
import selectShift, { deselectShift } from '../../actions/select-shift';

import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import { Shift } from '@modules/schedules/types/objects';

Calendar.momentLocalizer(moment);

const SchedulesIntegrated = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [week, setWeek] = useState(moment().startOf('isoWeek'));
  const [isFetching, setIsFetching] = useState(false);

  const shifts = useAppSelector(schedulesSelector.shifts);
  const shift = useAppSelector(schedulesSelector.selectedShift) as Shift;

  useEffect(() => {
    const load = async () => {
      setIsFetching(true);
      await dispatch(fetchShifts());
      setIsFetching(false);
    };
    load();
    return () => {
      dispatch(deselectShift());
    };
  }, [dispatch, fetchShifts, deselectShift, setIsFetching]);

  return (
    <Container name="Schedules">
      <AddExchangeForm
        show={!!shift}
        shift={shift}
        schedulesIntegrated
        onClose={() => {
          // When deselecting a shift, the create form will swap to another type of input.
          // When you deselect the shift immediately, it will first swap to this other view before the modal is closed
          // the timeout prevents this strange effect.
          setTimeout(() => {
            dispatch(deselectShift());
          }, 200);
        }}
      />
      <Container.Content>
        <Overview>
          {/* @ts-expect-error */}
          <Calendar
            events={shifts}
            date={week.toDate()}
            showMultiDayTimes
            defaultView="week"
            views={['week']}
            startAccessor="start_time"
            endAccessor="end_time"
            onNavigate={() => false} // prevents error
            onSelectEvent={(selectedShift: Shift) => {
              if (selectedShift) {
                dispatch(selectShift(selectedShift.id));
                AnalyticsService.track(EEventNames.VIEWED_INTEGRATED_SHIFT);
              }
            }}
            components={{
              toolbar: ({ date }: any) => (
                <ToolBar>
                  <WeekSelector
                    date={moment(date)}
                    min={0}
                    max={3}
                    onGoToToday={() => setWeek(moment())}
                    onGoToPreviousWeek={() => setWeek(week.clone().subtract(1, 'week'))}
                    onGoToNextWeek={() => setWeek(week.clone().add(1, 'week'))}
                    isFetching={isFetching}
                  />
                </ToolBar>
              )
            }}
            formats={{
              dayFormat: 'dddd D',
              eventTimeRangeFormat: ({ start, end }: any) => `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`,
              eventTimeRangeStartFormat: ({ start }: any) => `${moment(start).format('HH:mm')} -`,
              eventTimeRangeEndFormat: ({ end }: any) => {
                const endTime = moment(end);
                if (endTime.hours() === 0 && endTime.minutes() === 0) {
                  return t('schedules:container_no_end_time');
                }
                return `- ${endTime.format('HH:mm')}`;
              },
            }}
          />
        </Overview>
      </Container.Content>
    </Container>
  );
};

export default pageWrapper(EEventNames.VIEWED_INTEGRATED_SCHEDULES)(SchedulesIntegrated);
